import React, {useEffect, useMemo, useRef} from 'react';
import {useTheme, Flex, Box, HStack} from '@chakra-ui/react';
import ConvinceMeToGoButton from '../../../pages/Event/ConvinceMeToGoButton';
import {TextA12, TextG10, TextG12, TextG14} from '../../typography';
import {Event} from '../../../types/acrticle';
import {generatePath, useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {getEventDate} from '../../../utils/date';
import useGetUserLocation from '../../../hooks/useUserLocation';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {getDisplayPrice} from '../../../utils/price';
import DotSeparator from '../../DotSeparator';
import Icon from '../../Icon';
import {useOffers} from '../../../hooks/useOffers';
import OffersCardBanner from '../../OffersCardBanner';
import {useFetchEventRSVPStats} from '../../../hooks/useEvent';
import {calculateAndGetDistance, getDistanceString} from '../../../utils/distance';
import PremiumCard from '../../Card/PremiumCard';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import CardBadge from '../../CardBadge';
import RSVPController from '../../../pages/Event/RSVP/RSVPController';
import useAuth from '../../../hooks/useAuth';
import {BlueButton} from '../../button';
import UseablePassPill from './UseablePassPill';
import {useFetchUserTickets} from '../../../hooks/useUserTickets';
import {filter} from 'lodash';
import NotWalkaboutAffiliatedPill from './NotWalkaboutAffiliatedPill';
import InterestedToggle from '../../InterestedToggle';
const iconStyle = {
  color: 'grey',
  fontSize: '12px',
  marginRight: '5px',
};

const optionStyle: React.CSSProperties = {
  fontSize: '18px',
  paddingTop: '3px',
};

const PremiumEventCard = ({
  event,
  leftTopItem,
  rightTopItem,
  height,
  modal,
  hideRSVP,
  isFeatured = false,
  isSlide,
  onClick,
  isPreview,
}: {
  event?: Event;
  leftTopItem?: any;
  rightTopItem?: any;
  height?: string;
  modal?: boolean;
  hideRSVP?: boolean;
  isFeatured?: boolean;
  isSlide?: boolean;
  onClick?: (e) => void;
  isPreview?: boolean;
}) => {
  if (!event || !event.rsvp_status) return null; // TODO: revisit the loading flow to prevent this check
  const theme = useTheme();
  let userLocation;
  if (!isPreview) {
    userLocation = useGetUserLocation();
  }
  const cardChildContent = useRef<HTMLDivElement>(null);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {user} = useAuth();
  const eventDiscount = user?.activeMembershipPlan?.event_discount;

  const fetchTickets = useFetchUserTickets(!!user);
  const tickets = fetchTickets.data?.data;
  const eventTickets = filter(tickets, (ticket) => ticket.event_id == event.id);
  const userHasTickets = eventTickets && eventTickets.length > 0;

  const fetchOffers = useOffers({
    articleType: 'eventId',
    articleId: event.id.toString(),
    enabled: !onClick && isVisible && !!user,
  });

  const {data} = useFetchEventRSVPStats(event.id, !onClick && isVisible && !!user);
  const rsvp_stats = data?.data.rsvp_stats;
  const usersGoing = rsvp_stats?.GOING;

  const isWalkaboutEvent = Boolean(event.is_walkabout_official);

  const activeOffers = useMemo(() => {
    if (fetchOffers && fetchOffers.offers && fetchOffers.offers.length) {
      return <OffersCardBanner fullCard={true} offers={fetchOffers.offers} />;
    }
  }, [fetchOffers, theme]);

  useEffect(() => {
    if (wasEverVisible && event?.id.toString()) {
      addVisibleArticle({id: event.id.toString(), type: 'event'});
    }
  }, [wasEverVisible]);

  const eventDate = useMemo(
    () =>
      !!event.is_recurring
        ? event.recurring_schedule
        : getEventDate(event.start_date_time, event.end_date_time),
    [event]
  );

  return (
    <>
      <PremiumCard
        outline={event.is_walkabout_official ? '1px solid #FFC0C6' : 'none'}
        article={event}
        isSlide={isSlide}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
            return;
          }
          if (
            e?.target.tagName === 'BUTTON' ||
            e?.target.parentNode.tagName === 'BUTTON' ||
            e?.target.parentNode.parentNode.tagName === 'BUTTON'
          )
            return;

          const route = modal
            ? {
                pathname,
                search: `?id=${event.id.toString()}&articleType=${
                  event.type ? event.type.toLowerCase() : 'event'
                }`,
              }
            : generatePath(ROUTES.EVENT, {
                eventId: event.id.toString(),
              });
          storeScrollPositionInSession();
          navigate(route);
        }}
        overlayBackgroundColor={isFeatured ? '#2729295C' : ''}
        overlayHeight={
          isFeatured && cardChildContent?.current?.clientHeight
            ? `${cardChildContent.current.clientHeight + 24}px`
            : ''
        }
        borderRadius={isFeatured ? '0px' : '12px'}
        childPaddingX={4}
        height={height}
        coverImage={event.image_url || DefaultEventImage}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}>
        <Box ref={cardChildContent}>
          {event.is_walkabout_official ? (
            <CardBadge label="WALKABOUT OFFICIAL" w="145px" top="-12px" />
          ) : null}
          <Flex justifyContent="space-between" py={1} alignItems="center" textAlign="center">
            <Flex
              mt={event.is_walkabout_official ? '-4px' : 0}
              ref={ref}
              alignItems="center"
              textAlign="center"
              color={theme.colors.grey}>
              {!!event.is_recurring && <Icon iconName="fi-rr-time-past" style={iconStyle} />}
              <TextG10>{eventDate}</TextG10>
              {event.price && (
                <>
                  {/* <DotSeparator color={theme.colors.grey} />
                <TextG10>
                  {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
                </TextG10> */}
                  {event.latitude && event.longitude && userLocation?.data && (
                    <>
                      <DotSeparator color={theme.colors.grey} />
                      <TextG10 sx={{wordWrap: 'normal !important'}}>
                        {getDistanceString(
                          calculateAndGetDistance(
                            {
                              latitude: userLocation?.data?.latitude,
                              longitude: userLocation?.data?.longitude,
                            },
                            {latitude: Number(event.latitude), longitude: Number(event.longitude)}
                          )
                        )}
                      </TextG10>
                    </>
                  )}
                </>
              )}
            </Flex>
            <Flex gap="5px">
              <TextG12
                fontSize={event.has_tickets && eventDiscount && eventDiscount > 0 ? '10px' : '12px'}
                color={theme.colors.lightBlack}
                as={
                  event.price > 0 &&
                  !event.price_override &&
                  event.has_tickets &&
                  eventDiscount &&
                  eventDiscount > 0 &&
                  !event.price_override
                    ? 's'
                    : ''
                }>
                {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
              </TextG12>
              {event.price > 0 &&
                event.has_tickets &&
                eventDiscount &&
                eventDiscount > 0 &&
                !event.price_override && (
                  <TextG12 color={theme.colors.lightBlack}>
                    {getDisplayPrice(event.price - (event.price * eventDiscount) / 100)}
                  </TextG12>
                )}
            </Flex>
          </Flex>
          <Flex mb={1} alignItems={'bottom'} width={'100%'} justifyContent={'space-between'}>
            <TextG14
              isTruncated="&hellip;"
              noOfLines={2}
              fontWeight={700}
              color={theme.colors.black}>
              {event.title}
            </TextG14>
          </Flex>
          {!isPreview && <UseablePassPill event={event} />}
          {!isWalkaboutEvent && <NotWalkaboutAffiliatedPill />}
          <Flex my={2} alignItems="center" justifyContent="flex-start" gap="0.3rem">
            {!onClick && event.has_tickets && (
              <BlueButton
                w="120px"
                height="30px"
                onClick={() =>
                  userHasTickets
                    ? navigate(`/tickets/${event.id}`)
                    : navigate(`/event/${event.id}/tickets`, {
                        replace: true,
                      })
                }>
                <TextG10 fontWeight="500">{userHasTickets ? 'See Tickets' : 'Get Tickets'}</TextG10>
              </BlueButton>
            )}
            {isWalkaboutEvent && !onClick && (
              <ConvinceMeToGoButton
                event={event}
                textColor={
                  event.has_tickets || event.rsvp_status?.status !== null ? 'black' : 'white'
                }
                background={event.has_tickets ? 'white' : '#276ACD'}
                typographySize="sm"
                buttonProps={{
                  flexGrow: 0.3,
                  height: '2rem',
                  border: event.has_tickets ? '1px solid black' : '1px solid white',
                  maxW: '100%',
                }}
              />
            )}
            {isWalkaboutEvent && !onClick && !hideRSVP && !event.has_tickets && (
              <RSVPController
                event={event}
                buttonProps={{
                  flexGrow: 0.2,
                  height: '2rem',
                  border: '1px solid white',
                  maxW: '50%',
                }}
                variant="sm"
              />
            )}
            {!isWalkaboutEvent && (
              <Box mt={1}>
                <InterestedToggle
                  event={event}
                  width="fit-content"
                  height="20px"
                  fontSize="12px"
                  switchSize="sm"
                  switchLocation="left"
                  hasBorder={false}
                />
              </Box>
            )}
            {usersGoing > 0 && (
              <TextG10
                whiteSpace="nowrap"
                style={{marginLeft: 'auto'}}
                color={theme.colors.grey}>{`${usersGoing} Going`}</TextG10>
            )}
          </Flex>
        </Box>
      </PremiumCard>
    </>
  );
};

export default PremiumEventCard;
