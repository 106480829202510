import {Flex, Badge, theme, useTheme, FlexProps} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import {Place} from '../../../types/acrticle';
import Icon from '../../Icon';
import {TextG10} from '../../typography';

interface PointBoostBadgeProps extends FlexProps {
  place: Place;
  flexDir?: FlexProps['flexDir'];
  alignItems?: FlexProps['alignItems'];
}

const PointBoostBadge = ({place, flexDir = 'row', alignItems = 'center'}: PointBoostBadgeProps) => {
  const theme = useTheme();

  const boostStarted = dayjs().utc().isAfter(place.points_boost_starts_at);
  const boostNotEnded = !dayjs().utc().isAfter(place.points_boost_ends_at);
  const pointsBoostAmount = place.points_boost_amount;

  const pointBoostIsActive =
    place.is_points_boost_active &&
    pointsBoostAmount &&
    pointsBoostAmount > 0 &&
    boostStarted &&
    boostNotEnded;

  if (!pointBoostIsActive) {
    return null;
  }

  return (
    <Flex gap="4px" flexDir={flexDir} alignItems={alignItems} my="4px">
      <Badge
        w="fit-content"
        textTransform="none"
        color="#E02F84"
        bg="#FFEDF6"
        borderRadius="4px"
        px="4px"
        py="4px">
        <Flex flexDir="row" gap="2px" alignItems="center">
          <Icon iconName="fi-rr-angle-double-small-up" style={{width: '12px', height: '12px'}} />
          <TextG10 fontWeight={700} color={theme.colors.rose}>
            Points Boost
          </TextG10>
        </Flex>
      </Badge>
      <Flex flexDir="row" gap="3px" whiteSpace="nowrap" mr="-100px">
        <TextG10
          fontWeight="500"
          color={theme.colors.rose}>{`+${pointsBoostAmount.toLocaleString()} Points`}</TextG10>
        <TextG10> when you check-in here!</TextG10>
      </Flex>
    </Flex>
  );
};

export default PointBoostBadge;
